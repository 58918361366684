.str-chat__channel-search .str-chat__channel-search-bar {
  height: auto !important;
  border-bottom: 1px solid #e2e8f0 !important;
  padding: 9px 16px 8px 16px !important;
}

.str-chat__channel-search .str-chat__channel-search-bar .str-chat__channel-search-input--wrapper {
  padding: 0 !important;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.str-chat__channel-search-input--wrapper-active .str-chat__channel-search-input--icon svg path {
  fill: #747881 !important;
}

.str-chat__channel-search .str-chat__channel-search-result-list .str-chat__channel-search-result {
  padding: 16px !important;
  border-bottom: 1px solid #e2e8f0 !important;
  transition: all 120ms ease-in-out;
  &:hover {
    background-color: #f8fafc !important;
  }
}

.str-chat__channel-preview .str-chat__channel-preview-end .str-chat__channel-preview-end-first-row {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #000;
}

.str-chat__channel-search .str-chat__channel-search-result-list .str-chat__channel-search-result {
  align-items: flex-start !important;
}

.str-chat__channel-preview-messenger--left .str-chat__avatar .str-chat__avatar-fallback {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #000;
}

.str-chat__channel-preview .str-chat__channel-preview-end .str-chat__channel-preview-messenger--name,
.str-chat__channel-preview .str-chat__channel-preview-end .str-chat__channel-preview-messenger--last-message {
  display: inline-flex;
  align-items: flex-start;
  gap: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  h1,
  p {
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family:
      Inter,
      ui-sans-serif,
      system-ui,
      sans-serif,
      Apple Color Emoji,
      Segoe UI Emoji,
      Segoe UI Symbol,
      Noto Color Emoji;
    margin: 0;
    padding: 0;
    opacity: 1;
    color: rgb(100, 116, 139);
  }
}
.str-chat__channel-header {
  padding: 8px 16px !important;
  gap: 8px !important;
}
.str-chat__channel-search
  .str-chat__channel-search-bar
  .str-chat__channel-search-input--wrapper
  .str-chat__channel-search-input--icon {
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.str-chat__channel-search
  .str-chat__channel-search-bar
  .str-chat__channel-search-input--wrapper
  .str-chat__channel-search-input--clear-button {
  position: absolute;
  z-index: 2;
  right: 0;
}

.str-chat__channel-search .str-chat__channel-search-bar .str-chat__channel-search-input--wrapper input {
  padding: 8px 32px;
  position: relative;
  z-index: 2;
}

.str-chat__channel-search .str-chat__channel-search-bar .str-chat__channel-search-bar-button {
  display: none !important;
}
