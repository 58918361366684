.fc .fc-daygrid-day.fc-day-today {
  @apply bg-neutral-50;
  .fc-daygrid-day-number {
    @apply text-white bg-primary-900 rounded-s-lg;
  }
}
.fc-button-group {
  @apply flex;
}
.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event {
  @apply text-neutral-500 cursor-pointer;
  .fc-calendar-event {
    @apply flex gap-1 items-center rounded-[4px] px-1 w-full h-full transition-all hover:bg-neutral-900 hover:text-white;
    small {
      @apply text-right overflow-hidden max-w-[100px] text-ellipsis;
    }
    &.booked {
      @apply text-success-700 hover:text-white font-semibold;
      .dot {
        @apply bg-success-400 border-2 border-success-600;
      }
    }
    &.pending {
      .dot {
        @apply bg-warning-600 border-2 border-warning-600;
      }
    }
    .dot {
      @apply w-2 h-2 rounded-full bg-primary-50 border border-neutral-500;
      &.red {
        @apply bg-error-400 border-2 border-error-600;
      }
    }
  }
}
.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  @apply bg-transparent;
}
.fc .fc-daygrid-more-link {
  @apply text-xs text-neutral-900 uppercase font-semibold flex px-1 mt-2 bg-[rgba(255,255,255,0)] w-full hover:no-underline hover:bg-primary-200;
}
.fc .fc-button {
  @apply bg-white text-neutral-900 border border-neutral-200 rounded-full transition-all uppercase text-xs font-bold px-3 py-2
  hover:bg-neutral-100 hover:text-neutral-900 hover:border-neutral-200;
}
.fc .fc-button-primary:disabled {
  @apply bg-neutral-100 text-neutral-400 border-neutral-200;
}
.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-2;
}
.fc .fc-toolbar-title {
  @apply text-base uppercase;
}
.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  @apply border-neutral-200;
}
table {
  @apply bg-white;
}
.fc .fc-col-header-cell-cushion {
  @apply text-xs font-bold p-2 text-neutral-950 bg-neutral-100 hover:no-underline;
}
